import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import auth from '@/plugins/auth';
import userTypes from '@/plugins/userTypes';

Vue.use(Vuex);

const { body } = document;

export default new Vuex.Store({
  state: {
    classroomVideoTypes: ['Youtube', 'Vimeo', 'Otros'],
    user: auth.user(),
    loading: false,
    isCollapse: false,
    theme: 'light',
    userTypes,
    heightTable: 'calc(100vh - 340px)',
    showModalChangePassword: false,
    departments: [],
    provinces: [],
    districts: [],
    cities: [],
    roles: [
      'COMUNITY MANAGER',
      'ASESOR DE CANAL DIGITAL',
      'ASESOR DE VENTAS',
      'ASESOR LAVADO DE ACTIVOS',
      'ASESORA DE MAF',
      'JEFE DE ASESORAS DE CONEXOS',
      'ASESORAS DE CONEXOS',
      'CAJERA',
      'JEFA ADV',
      'ADV´S',
      'JEFA COMERCIAL',
      'ASESORAS INMATRICULACION',
      'ALMACENERO',
      'JEFE PDS',
      'ASESOR(A) DE ENTREGAS',
      'VIGILANTES',
      'PLANNERS AUTOTUNNING',
      'ASESORA DE CITAS',
      'TASADORES',
      'ASESOR DE VENTAS SEMINUEVO',
      'JEFE DE SEMINUEVOS',
      'FINANZAS',
      'LICITADOR',
      'CONTROL TOTAL',
    ],
    origins: [
      { label: 'Interno', value: 'INTERNO' },
      { label: 'Externo', value: 'EXTERNO' },
    ],
    vehicleModels: [],
    categories: [],
    units: [],
    modules: [],
    currencies: [],
    vehicleSections: [],
    tdps: [],
    locations: [],
  },
  mutations: {
    ON_SET_MODELS(state, payload) {
      state.vehicleModels = payload;
    },
    ON_SET_LOCATIONS(state, payload) {
      state.locations = payload;
    },
    ON_SET_TDPS(state, payload) {
      state.tdps = payload;
    },
    ON_SET_VEHICLESECTIONS(state, payload) {
      state.vehicleSections = payload;
    },
    ON_SHOW_MODAL_CHANGE_PASSWORD(state) {
      state.showModalChangePassword = true;
    },
    ON_HIDE_MODAL_CHANGE_PASSWORD(state) {
      state.showModalChangePassword = false;
    },
    REFRESH_USER_DATA(state) {
      state.user = auth.user();
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    ON_CHANGE_THEME(state) {
      if (state.theme === 'dark') {
        body.classList.remove('theme__dark');
        body.classList.add('theme__light');
        state.theme = 'light';
      } else {
        body.classList.remove('theme__light');
        body.classList.add('theme__dark');
        state.theme = 'dark';
      }
    },
    ON_HIDE_SHOW_SIDEBAR(state) {
      if (state.isCollapse) {
        body.classList.remove('is-collapse');
        state.isCollapse = false;
      } else {
        body.classList.add('is-collapse');
        state.isCollapse = true;
      }
    },
    ON_CLOSE_MENU(state) {
      body.classList.remove('show-menu');
      state.isShowMenu = false;
    },
    ON_OPEN_MENU(state) {
      body.classList.add('show-menu');
      state.isShowMenu = true;
    },
    async ON_FETCH_UBIGEO(state) {
      await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL_BASE}ubigeo`,
      }).then((response) => {
        const { data } = response;
        state.departments = data.data.departments;
        state.provinces = data.data.provinces;
        state.districts = data.data.districts;
        state.cities = data.data.cities;
      });
    },
    ON_SET_CATEGORIES(state, payload) {
      state.categories = payload;
    },
    ON_SET_UNITS(state, payload) {
      state.units = payload;
    },
    ON_SET_CURRENCIES(state, payload) {
      state.currencies = payload;
    },
    ON_SET_MODULES(state, payload) {
      state.modules = payload;
    },
  },
  actions: {
    ON_SET_MODELS(context, payload) {
      context.commit('ON_SET_MODELS', payload);
    },
    ON_SET_LOCATIONS(context, payload) {
      context.commit('ON_SET_LOCATIONS', payload);
    },
    ON_SET_TDPS(context, payload) {
      context.commit('ON_SET_TDPS', payload);
    },
    ON_SET_VEHICLESECTIONS(context, payload) {
      context.commit('ON_SET_VEHICLESECTIONS', payload);
    },
    ON_FETCH_UBIGEO(context) {
      context.commit('ON_FETCH_UBIGEO');
    },
    ON_SHOW_MODAL_CHANGE_PASSWORD(context) {
      context.commit('ON_SHOW_MODAL_CHANGE_PASSWORD');
    },
    ON_HIDE_MODAL_CHANGE_PASSWORD(context) {
      context.commit('ON_HIDE_MODAL_CHANGE_PASSWORD');
    },
    ON_CHANGE_THEME(context) {
      context.commit('ON_CHANGE_THEME');
    },
    ON_HIDE_SHOW_SIDEBAR(context) {
      context.commit('ON_HIDE_SHOW_SIDEBAR');
    },
    ON_CLOSE_MENU(context) {
      context.commit('ON_CLOSE_MENU');
    },
    ON_OPEN_MENU(context) {
      context.commit('ON_OPEN_MENU');
    },
    SET_LOADING(context, payload) {
      context.commit('SET_LOADING', payload);
    },
    REFRESH_USER_DATA(context) {
      context.commit('REFRESH_USER_DATA');
    },
    ON_LOGIN(context, payload) {
      auth.setToken(payload);
      axios.defaults.baseURL = `${process.env.VUE_APP_API_URL_BASE}dashboard/`;
      context.commit('REFRESH_USER_DATA');
    },
    ON_SET_CATEGORIES(context, payload) {
      context.commit('ON_SET_CATEGORIES', payload);
    },
    ON_SET_UNITS(context, payload) {
      context.commit('ON_SET_UNITS', payload);
    },
    ON_SET_MODULES(context, payload) {
      context.commit('ON_SET_MODULES', payload);
    },
    ON_SET_CURRENCIES(context, payload) {
      context.commit('ON_SET_CURRENCIES', payload);
    },
  },
  getters: {
    classroomVideoTypes(state) {
      return state.classroomVideoTypes;
    },
    vehicleModels(state) {
      return state.vehicleModels;
    },
    locations(state) {
      return state.locations;
    },
    tdps(state) {
      return state.tdps;
    },
    vehicleSections(state) {
      return state.vehicleSections;
    },
    roles(state) {
      return state.roles;
    },
    departments(state) {
      return state.departments;
    },
    cities(state) {
      return state.cities;
    },
    provinces(state) {
      return state.provinces;
    },
    districts(state) {
      return state.districts;
    },
    showModalChangePassword(state) {
      return state.showModalChangePassword;
    },
    heightTable(state) {
      return state.heightTable;
    },
    userTypes(state) {
      return state.userTypes;
    },
    theme(state) {
      return state.theme;
    },
    isCollapse(state) {
      return state.isCollapse;
    },
    loading(state) {
      return state.loading;
    },
    user(state) {
      return state.user;
    },
    postStates(state) {
      return state.postStates;
    },
    units(state) {
      return state.units;
    },
    currencies(state) {
      return state.currencies;
    },
    categories(state) {
      return state.categories;
    },
    modules(state) {
      return state.modules;
    },
    origins(state) {
      return state.origins;
    },
  },
});
