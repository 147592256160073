<template>
  <aside class="wrapper__aside" :class="{ 'is-collapse': isCollapse }">
    <div class="wrapper__aside-topbar">
      <img src="@/assets/images/intranort-logo.png" alt="Logo intranort" class="img-fluid logo" />
      <el-button type="text" @click="onHideShowSidebar()" class="btn-collapse">
        <template v-if="isCollapse">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-circle"
          >
            <circle cx="12" cy="12" r="10"></circle>
          </svg>
        </template>
        <template v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-disc"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
        </template>
      </el-button>
      <el-button type="text" class="btn-close-menu" @click="onCloseMenu()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-x"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </el-button>
    </div>
    <el-scrollbar :style="{ height: menuHeight, overflowX: 'hidden' }" class="hide-x">
      <el-menu :collapse="isCollapse" :router="true">
        <!-- <el-submenu index="1">
          <template slot="title">
            <i class="fa fa-dashboard"></i>
            <span>Tablero</span>
          </template>
          <el-menu-item-group title="Formulario">
            <el-menu-item index="/">Ini</el-menu-item>
            <el-menu-item index="/formulario">Formulario</el-menu-item>
          </el-menu-item-group>
        </el-submenu> -->
        <el-submenu index="2">
          <template slot="title">
            <i class="fa fa-users"></i>
            <span>Usuarios</span>
          </template>
          <el-menu-item-group class="no-title">
            <el-menu-item index="/dashboard/users">Lista</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="fa-solid fa-warehouse"></i>
            <span>Locales</span>
          </template>
          <el-menu-item-group class="no-title">
            <el-menu-item index="/dashboard/locals">Locales</el-menu-item>
            <el-menu-item index="/dashboard/warehouses">Almacenes</el-menu-item>
            <el-menu-item index="/dashboard/brands">Marcas</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="4">
          <template slot="title">
            <i class="fa-solid fa-cubes"></i>
            <span>Módulos y roles</span>
          </template>
          <el-menu-item-group class="no-title">
            <el-menu-item index="/dashboard/modules">Módulos</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group class="no-title">
            <el-menu-item index="/dashboard/roles">Roles</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- Tablas auxiliares -->
        <el-submenu index="6">
          <template slot="title">
            <i class="fa-solid fa-database"></i>
            <span>Tablas auxiliares</span>
          </template>
          <el-menu-item-group class="no-title">
            <el-menu-item index="/dashboard/tables/identity-document-types"
              >Documentos de identidad</el-menu-item
            >
            <el-menu-item index="/dashboard/tables/catchment-origins"
              >Origenes de captación</el-menu-item
            >
            <el-menu-item index="/dashboard/tables/digital-channel-states"
              >Estados de canal digital</el-menu-item
            >
            <el-menu-item index="/dashboard/tables/locations">Ubicaciones</el-menu-item>
            <el-menu-item index="/dashboard/tables/payment-methods">Métodos de pago</el-menu-item>
            <el-menu-item index="/dashboard/tables/kindreds">Parentesco</el-menu-item>
            <el-menu-item index="/dashboard/tables/jobs">Tipos de Trabajo</el-menu-item>
            <el-menu-item index="/dashboard/tables/structures">Estructura</el-menu-item>
            <el-menu-item index="/dashboard/tables/benefits">Beneficios</el-menu-item>
            <el-menu-item index="/dashboard/tables/units">Unidades</el-menu-item>
            <el-menu-item index="/dashboard/tables/categories">Categorias</el-menu-item>
            <el-menu-item index="/dashboard/tables/accesories">Accesorios</el-menu-item>
            <el-menu-item index="/dashboard/tables/safes">Seguros</el-menu-item>
            <el-menu-item index="/dashboard/tables/prepaid-maintenance"
              >Mantenimiento Prepagado</el-menu-item
            >
            <el-menu-item index="/dashboard/tables/financial-entities"
              >Entidades financieras</el-menu-item
            >
            <el-menu-item index="/dashboard/tables/derivative-financial-entities"
              >Entidades financieras hijas</el-menu-item
            >
            <el-menu-item index="/dashboard/tables/currencies">Monedas</el-menu-item>
            <el-menu-item index="/dashboard/tables/reasons">Motivos</el-menu-item>
            <el-menu-item index="/dashboard/tables/document-types">Tipos de documento</el-menu-item>
            <el-menu-item index="/dashboard/tables/appointments/motives-list"
              >Motivos de citas</el-menu-item
            >
            <el-menu-item index="/dashboard/tables/checklist/list">Checklist</el-menu-item>
            <el-menu-item index="/dashboard/tables/banks">Bancos</el-menu-item>
            <el-menu-item index="/dashboard/tables/document-types">Tipo de documentos</el-menu-item>
            <el-menu-item index="/dashboard/tables/insurances">Aseguradoras</el-menu-item>
            <el-menu-item index="/dashboard/tables/sale-statuses">Estados de venta</el-menu-item>
            <el-menu-item index="/dashboard/tables/type-incidents"
              >Tipo de Incidencias</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <!-- Licitaciones -->
        <el-submenu index="8">
          <template slot="title">
            <i class="fa-solid fa-database"></i>
            <span>Licitaciones</span>
          </template>
          <el-menu-item-group class="no-title">
            <el-menu-item index="/dashboard/tables/tenders/phases">Fases</el-menu-item>
            <el-menu-item index="/dashboard/tables/tenders/states">Estados</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- Empresas -->
        <el-menu-item index="/dashboard/companies">
          <i class="fas fa-building"></i>
          <span>Empresas</span>
        </el-menu-item>
        <!-- Ubigeo -->
        <el-menu-item index="/dashboard/ubigeo">
          <i class="fa-solid fa-map-location"></i>
          <span>Ubigeo</span>
        </el-menu-item>
        <!-- Logs -->
        <el-menu-item index="/dashboard/logs">
          <i class="fa-solid fa-list"></i>
          <span>Logs</span>
        </el-menu-item>
        <el-menu-item index="/dashboard/quotations/import">
          <i class="fas fa-money-check-alt"></i>
          <span>Cotizaciones</span>
        </el-menu-item>
        <!-- Configuración -->
        <el-submenu index="7">
          <template slot="title">
            <i class="fas fa-cogs"></i>
            <span>Configuración</span>
          </template>
          <el-menu-item-group class="no-title">
            <el-menu-item index="/dashboard/settings/backups">Backups</el-menu-item>
            <el-menu-item index="/dashboard/settings/release-vehicles"
              >Liberar vehículos</el-menu-item
            >
            <el-menu-item index="/dashboard/settings/conexos-renovations"
              >Conexos Renovaciones</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <!-- Aula virtual -->
        <el-submenu index="9">
          <template slot="title">
            <i class="fas fa-graduation-cap"></i>
            <span>Aula virtual</span>
          </template>
          <el-menu-item-group class="no-title">
            <el-menu-item index="/dashboard/classroom/videos">Videos</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- Publicador de mensajes -->
        <el-submenu index="10">
          <template slot="title">
            <i class="fas fa-comments"></i>
            <span>Mensajes</span>
          </template>
          <el-menu-item-group class="no-title">
            <el-menu-item index="/dashboard/messages/send">Enviar a todos</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- NISIRA -->
        <el-submenu index="11">
          <template slot="title">
            <i class="fas fa-database"></i>
            <span>NISIRA</span>
          </template>
          <el-menu-item-group class="no-title">
            <el-menu-item index="/dashboard/nisira/projects">Proyectos</el-menu-item>
            <el-menu-item index="/dashboard/nisira/responsibles">Responsables</el-menu-item>
            <el-menu-item index="/dashboard/nisira/areas">Áreas</el-menu-item>
            <el-menu-item index="/dashboard/nisira/type-uses">Tipos de uso</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-scrollbar>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarComponent',
  data: () => ({
    menuHeight: '1px',
  }),
  created() {
    this.menuHeight = `${window.innerHeight - 62}px`;
  },
  computed: {
    ...mapGetters(['isCollapse']),
  },
  methods: {
    onHideShowSidebar() {
      this.$store.commit('ON_HIDE_SHOW_SIDEBAR');
    },
    onCloseMenu() {
      this.$store.commit('ON_CLOSE_MENU');
    },
  },
};
</script>
