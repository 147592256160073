<template>
  <router-view />
</template>

<script>
export default {
  name: 'RootComponent',
  mounted() {
    this.onFetchUser();
  },
  methods: {
    onFetchUser() {
    },
  },
};
</script>
